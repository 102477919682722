import React, { useEffect } from "react";
import { Box, Text } from "rebass/styled-components";
import Container from "../components/UI/Container";
import Page from "../templates/Page";
import { useQueryParam } from "gatsby-query-params";
import KRGlue from "@lyracom/embedded-form-glue";
import styled from "styled-components";

const Wrapper = styled.div`
  .kr-embedded {
    margin-left: auto;
    margin-right: auto;
  }
  img {
    display: block;
  }
  #myPaymentForm {
    .kr-pan,
    .kr-expiry,
    .kr-security-code,
    input {
      height: auto;
      border: 2px solid #dfdfdf;
      margin-bottom: 20px;
      border-radius: 4px;
    }
    .kr-field-wrapper {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .kr-embedded .kr-icon-wrapper svg {
      width: 20px;
      path {
        fill: #003957;
      }
    }
    .kr-embedded.kr-help-button-inner-field .kr-help-button-wrapper.kr-inner .kr-help-button .kr-help-icon-wrapper {
      background-color: #003957;
    }
    button {
      margin-top: 20px;
      background-color: #003957 !important;
      border-radius: 4px;
      height: 50px;
      text-decoration: none;
      width: auto;
      margin-left: auto;
      margin-right: auto;
      display: block;
      padding: 15px 35px;
      & > span {
        text-transform: uppercase;
        font-weight: bold;
        font-family: Montserrat;
        display: flex;
        align-items: center;
        line-height: 1;
        &:after {
          content: "\\e80b";
          font-family: "fontello";
          color: #29cdfb;
          margin-left: 10px;
          font-size: 24px;
        }
      }
      &:hover {
        color: white;
        background-color: #29cdfb !important;
        & > span:after {
          color: white;
        }
      }
    }
   }

  input {
    vertical-align: middle;
    width: 100%;
    padding: 5px;
  }
`;
const button = styled(Wrapper)`
  width: 100%;
  height: 60px !important;
`;
const PaymentPage = () => {
  const montant = useQueryParam("montant", 0);
  const entityid = useQueryParam("entityid", 0);
  const label = useQueryParam("label", 0);
  const language = useQueryParam("lang", "fr");
  console.log(montant);
  console.log(entityid);
  const fixFrenchPaymentButton = () => {
    const elements = document.querySelectorAll(".kr-payment-button");
    const paymentButton = elements[0].childNodes[0];
    console.log(paymentButton.textContent);
    const amount = paymentButton.textContent
      .replace("PAY CFP", "")
      .replace(".00", "")
      .replaceAll(",", " ");
    // paymentButton.textContent = `PAYER ${amount} CFP`;
    paymentButton.textContent = `Valider`;
  };
  useEffect(() => {
    if (montant) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          amount: parseInt(montant),
          contrib: "Pay.nc 0.1",
          currency: "XPF",
          orderId: "SomeRandomString"
        })
      };
      fetch("https://www.pay.nc/api/tokenGen", requestOptions)
        .then(response => response.json())
        .then(data => {
          const endpoint = "https://epaync.nc/";
          const publicKey = "28099012:testpublickey_UBn7KjFbp2XOcqMy18j1wLWGkiz4Nh6asPqFo35q4vM88";
          const formToken = data.formToken;

          KRGlue.loadLibrary(endpoint, publicKey) /* Load the remote library */
            .then(({ KR }) =>
              KR.setFormConfig({
                /* set the minimal configuration */
                formToken: formToken,
                "kr-language": "en" /* to update initialization parameter */,
                "kr-payment-button": "this will cost %amount-and-currency%"
              })
            )
            .then(({ KR }) => {
              return KR.addForm("#myPaymentForm");
            })
            .then(({ KR, result }) => {
              return KR.showForm(result.formId);
            })
            .then(({ KR }) => {
              KR.onFormReady(() => {
                if (language == "fr") {
                  fixFrenchPaymentButton();
                }
              });

              return KR.setFormConfig({ "kr-language": language });
            });
        });
    }
  });
  return (
    <Page title="Formulaire de paiement">
      <Box
      pb={10}
      pt="120px"
      px={[3,4]}
      mx="auto"
      maxWidth={["100%","550px"]}
      bg="white">
        <Wrapper className="form">
          <Box maxWidth="350px" mx="auto">
            <Box mb={6}>
              <Text fontSize={4} textAlign="center">{label}</Text>
            </Box>
            <Box mb={6}>
              <Text fontSize={1}>Total</Text>
              <Box id="montant" fontSize={3} fontWeight="bold" mt={1}>{montant + "CFP"}</Box>
            </Box>
            <Box mb={4}>
              <Text fontSize={1}>Entreprise</Text>
              <Box mt={1} maxWidth="160px">
                <img src={"../entity/" + entityid + "/logo.svg"}></img>
              </Box>
            </Box>
            <Box className="container">
              <Box
              id="myPaymentForm"              
              sx={{
                "& > .kr-embedded": {
                  width: "100%"
                }
              }}></Box>
            </Box>
          </Box>
        </Wrapper>
      </Box>
    </Page>
  );
};

export default PaymentPage;
